var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"height":"100%","display":"flex","flex-direction":"column"},attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('page-title',{attrs:{"title":"My Shift Requests","badge":this.total}})],1),_c('v-row',{staticClass:"mt-0 mb-1",attrs:{"justify":"space-between","align":"center"}},[_c('v-col',{staticClass:"d-flex align-start",staticStyle:{"gap":"0.5rem"},attrs:{"sm":"12","md":"3","cols":"12"}},[(_vm.$has(_vm.perms.ResourceShiftRequests.Create))?_c('v-btn',{attrs:{"color":"deep-purple","dark":""},on:{"click":_vm.createShiftRequest}},[_c('i',{staticClass:"far fa-plus mr-2"}),_vm._v("New Shift Request ")]):_vm._e()],1),_c('v-col',{staticClass:"d-flex flex-row align-center justify-end flex-wrap",staticStyle:{"gap":"0.5rem"},attrs:{"md":"9","sm":"12","cols":"12"}},[_c('columns-visibility-control',{attrs:{"defaults":_vm.defaultHeaders,"withColumnsOrder":true,"storageKey":_vm.storageKey},model:{value:(_vm.headers),callback:function ($$v) {_vm.headers=$$v},expression:"headers"}}),(false)?_c('v-sheet',{staticClass:"d-inline-flex",attrs:{"elevation":"2","height":"32","rounded":""}},[_c('v-btn-toggle',{attrs:{"mandatory":"","color":"info","dense":"","group":""},model:{value:(_vm.tableMode),callback:function ($$v) {_vm.tableMode=$$v},expression:"tableMode"}},[_c('v-btn',{staticStyle:{"border-radius":"4px"},attrs:{"value":'list',"color":"white"}},[_c('v-icon',[_vm._v("fal fa-list")])],1),_c('v-btn',{staticStyle:{"border-radius":"4px"},attrs:{"value":'cards',"color":"white"}},[_c('v-icon',[_vm._v("fal fa-folders")])],1)],1)],1):_vm._e(),_c('v-text-field',{ref:"mainSearch",staticClass:"table-search-field",style:({
          'max-width':
            _vm.mainSearchInFocus || (_vm.options.search != '' && _vm.options.search != null)
              ? '200px'
              : '110px',
        }),attrs:{"label":"Search","dense":"","solo":"","hide-details":"","clearable":"","prepend-inner-icon":"far fa-search","loading":_vm.loadingStates.table},on:{"focus":function($event){_vm.mainSearchInFocus = true},"blur":function($event){_vm.mainSearchInFocus = false}},model:{value:(_vm.options.search),callback:function ($$v) {_vm.$set(_vm.options, "search", $$v)},expression:"options.search"}}),_c('refresh',{attrs:{"loading":_vm.loadingStates.table},on:{"refresh":function($event){return _vm.getDataDebounced()}}})],1)],1),_c('v-data-table',{staticClass:"elevation-2 resource-shift-requests-table table-sticky-header-exclude-footer",staticStyle:{"overflow":"overlay","height":"100%","flex":"1 1 auto","display":"flex","flex-direction":"column"},attrs:{"dense":"","headers":_vm.tableHeader,"items":_vm.entities,"options":_vm.options,"server-items-length":_vm.total,"items-per-page":_vm.options.itemsPerPage,"loading":_vm.loadingStates.table,"footer-props":{
      showFirstLastPage: true,
      firstIcon: 'far fa-arrow-to-left',
      lastIcon: 'far fa-arrow-to-right',
      prevIcon: 'far fa-angle-left',
      nextIcon: 'far fa-angle-right',
      itemsPerPageOptions: [15, 30, 50, 100],
    }},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.rowClicked},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"color":"blue","absolute":"","indeterminate":""}})]},proxy:true},{key:"item.id",fn:function(ref){
    var item = ref.item;
return [_c('code',{staticClass:"mono-font ml-2 secondary--text fs-12px blue-grey lighten-5",staticStyle:{"flex":"none"}},[_vm._v(" #"+_vm._s(item.id)+" ")])]}},{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.name))])]}},{key:"item.state",fn:function(ref){
    var item = ref.item;
return [_c('resource-shift-request-state',{attrs:{"small":"","state":item.state}})]}},{key:"item.targetUser",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-inline-flex"},[_c('user-avatar',{attrs:{"user":item.targetUser,"hide-roles":"","disable-open-link":""}})],1)]}},{key:"item.sourceUser",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-inline-flex"},[_c('user-avatar',{attrs:{"user":item.sourceUser,"hide-roles":"","disable-open-link":""}})],1)]}},{key:"item.managementUser",fn:function(ref){
    var item = ref.item;
return [_c('user-avatar',{attrs:{"user":item.managementUser,"hide-roles":"","disable-open-link":"","show-no-user":"","no-user-text":"None"}})]}},{key:"item.managementProcessed",fn:function(ref){
    var item = ref.item;
return [(item.managementProcessed)?_c('v-chip',{staticStyle:{"font-size":"11px","font-weight":"600"},attrs:{"small":"","x-small":"","color":"amber","text-color":"gray darken-2"}},[_c('i',{staticClass:"far fa-check",staticStyle:{"line-height":"0"}})]):_c('span',[_c('i',{staticClass:"fad fa-clock fs-16px pl-2 text--disabled"})])]}},{key:"item.managementProcessedDate",fn:function(ref){
    var item = ref.item;
return [(item.managementProcessed)?_c('dater',{attrs:{"date":item.managementProcessedDate,"date-only":""}}):_c('span',[_c('i',{staticClass:"fad fa-clock fs-16px pl-2 text--disabled"})])]}},{key:"item.targetProcessed",fn:function(ref){
    var item = ref.item;
return [(item.targetProcessed)?_c('v-chip',{staticStyle:{"font-size":"11px","font-weight":"600"},attrs:{"small":"","x-small":"","color":"amber","text-color":"gray darken-2"}},[_c('i',{staticClass:"far fa-check",staticStyle:{"line-height":"0"}})]):_c('span',[_c('i',{staticClass:"fad fa-clock fs-16px pl-2 text--disabled"})])]}},{key:"item.targetProcessedDate",fn:function(ref){
    var item = ref.item;
return [(item.targetProcessed)?_c('dater',{attrs:{"date":item.targetProcessedDate,"date-only":""}}):_c('span',[_c('i',{staticClass:"fad fa-clock fs-16px pl-2 text--disabled"})])]}},{key:"item.createdBy",fn:function(ref){
    var item = ref.item;
return [_c('user-avatar',{attrs:{"user":item.createdBy,"icon":""}})]}},{key:"item.updatedBy",fn:function(ref){
    var item = ref.item;
return [_c('user-avatar',{attrs:{"user":item.updatedBy,"icon":""}})]}},{key:"item.createDate",fn:function(ref){
    var item = ref.item;
return [_c('dater',{attrs:{"date":item.createDate,"date-only":""}})]}},{key:"item.updateDate",fn:function(ref){
    var item = ref.item;
return [_c('dater',{attrs:{"date":item.updateDate,"date-only":""}})]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-menu',{attrs:{"dense":"","offset-x":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var attrs = ref.attrs;
    var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","elevation":"0"}},'v-btn',attrs,false),on),[_c('i',{staticClass:"far fa-ellipsis-v",staticStyle:{"font-size":"16px"}})])]}}],null,true)},[_c('v-list',{staticClass:"more-options-menu"},[(
              _vm.$has(_vm.perms.ResourceShiftRequests.Update) &&
              item.state == _vm.enums.ResourceShiftRequestState.Draft.value
            )?_c('v-list-item',{on:{"click":function($event){return _vm.update(item)}}},[_c('v-list-item-icon',{staticClass:"mr-2 justify-center"},[_c('v-icon',{staticClass:"secondary--text",attrs:{"small":""}},[_vm._v("far fa-eye")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"secondary--text"},[_vm._v("View")])],1)],1):(
              _vm.$has(_vm.perms.ResourceShiftRequests.Update) &&
              item.state != _vm.enums.ResourceShiftRequestState.Draft.value
            )?_c('v-list-item',{on:{"click":function($event){return _vm.view(item)}}},[_c('v-list-item-icon',{staticClass:"mr-2 justify-center"},[_c('v-icon',{staticClass:"secondary--text",attrs:{"small":""}},[_vm._v("far fa-eye")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"secondary--text"},[_vm._v("View")])],1)],1):_vm._e(),(
              _vm.$has(_vm.perms.ResourceShiftRequests.Delete) &&
              item.state == _vm.enums.ResourceShiftRequestState.Draft.value
            )?_c('v-list-item',{on:{"click":function($event){return _vm.del(item)}}},[_c('v-list-item-icon',{staticClass:"mr-2 justify-center"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fal fa-trash-alt red--text")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"red--text"},[_vm._v("Delete")])],1)],1):_vm._e()],1)],1)]}},{key:"no-data",fn:function(){return [_c('img',{attrs:{"width":"500","src":"/img/art/fogg-no-connection-2.png"}}),_c('p',{staticClass:"font-weight-bold"},[_vm._v("No Data Available!")])]},proxy:true},{key:"loading",fn:function(){return [_c('video',{staticStyle:{"padding":"0.5rem"},attrs:{"width":"250","muted":"","loop":"","autoplay":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":"/img/art/astronaut-loves-music-4980476-4153140.mp4","type":"video/mp4"}})]),_c('p',{staticClass:"font-weight-bold"},[_vm._v("Searching the Cosmos...")])]},proxy:true}],null,true)}),_c('edit-shift-request',{ref:"editShiftRequest",on:{"save":_vm.onShiftRequestSaved,"sent":_vm.onShiftRequestSentToTarget,"close":_vm.onShiftRequestClosed}}),_c('view-shift-request',{ref:"viewShiftRequest",on:{"close":_vm.onShiftRequestClosed}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }