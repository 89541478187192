<template>
  <v-container fluid style="height: 100%; display: flex; flex-direction: column">
    <div class="d-flex align-center">
      <page-title title="My Shift Requests" :badge="this.total" />
    </div>
    <v-row class="mt-0 mb-1" justify="space-between" align="center">
      <v-col sm="12" md="3" cols="12" class="d-flex align-start" style="gap: 0.5rem">
        <v-btn
          color="deep-purple"
          dark
          @click="createShiftRequest"
          v-if="$has(perms.ResourceShiftRequests.Create)"
        >
          <i class="far fa-plus mr-2"></i>New Shift Request
        </v-btn>
      </v-col>
      <v-col
        md="9"
        sm="12"
        cols="12"
        class="d-flex flex-row align-center justify-end flex-wrap"
        style="gap: 0.5rem"
      >
        <!-- <v-sheet
          height="28"
          width="1"
          color="blue-grey lighten-4"
          class="mx-1"
        ></v-sheet> -->
        <columns-visibility-control
          :defaults="defaultHeaders"
          :withColumnsOrder="true"
          v-model="headers"
          :storageKey="storageKey"
        />
        <v-sheet elevation="2" height="32" rounded class="d-inline-flex" v-if="false">
          <v-btn-toggle v-model="tableMode" mandatory color="info" dense group>
            <v-btn :value="'list'" color="white" style="border-radius: 4px">
              <v-icon>fal fa-list</v-icon>
            </v-btn>
            <v-btn :value="'cards'" color="white" style="border-radius: 4px">
              <v-icon>fal fa-folders</v-icon>
            </v-btn>
          </v-btn-toggle>
        </v-sheet>
        <v-text-field
          v-model="options.search"
          label="Search"
          ref="mainSearch"
          class="table-search-field"
          @focus="mainSearchInFocus = true"
          @blur="mainSearchInFocus = false"
          :style="{
            'max-width':
              mainSearchInFocus || (options.search != '' && options.search != null)
                ? '200px'
                : '110px',
          }"
          dense
          solo
          hide-details
          clearable
          prepend-inner-icon="far fa-search"
          :loading="loadingStates.table"
        >
        </v-text-field>
        <refresh :loading="loadingStates.table" @refresh="getDataDebounced()"></refresh>
      </v-col>
    </v-row>
    <v-data-table
      style="
        overflow: auto;
        overflow: overlay;
        height: 100%;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
      "
      dense
      :headers="tableHeader"
      :items="entities"
      :options.sync="options"
      :server-items-length="total"
      :items-per-page="options.itemsPerPage"
      :loading="loadingStates.table"
      class="elevation-2 resource-shift-requests-table table-sticky-header-exclude-footer"
      @click:row="rowClicked"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'far fa-arrow-to-left',
        lastIcon: 'far fa-arrow-to-right',
        prevIcon: 'far fa-angle-left',
        nextIcon: 'far fa-angle-right',
        itemsPerPageOptions: [15, 30, 50, 100],
      }"
    >
      <template v-slot:progress>
        <v-progress-linear color="blue" absolute indeterminate></v-progress-linear>
      </template>

      <template v-slot:[`item.id`]="{ item }">
        <code class="mono-font ml-2 secondary--text fs-12px blue-grey lighten-5" style="flex: none">
          #{{ item.id }}
        </code>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <span class="font-weight-bold">{{ item.name }}</span>
      </template>

      <template v-slot:[`item.state`]="{ item }">
        <resource-shift-request-state small :state="item.state"></resource-shift-request-state>
      </template>

      <template v-slot:[`item.targetUser`]="{ item }">
        <div class="d-inline-flex">
          <user-avatar :user="item.targetUser" hide-roles disable-open-link></user-avatar>
        </div>
      </template>

      <template v-slot:[`item.sourceUser`]="{ item }">
        <div class="d-inline-flex">
          <user-avatar :user="item.sourceUser" hide-roles disable-open-link></user-avatar>
        </div>
      </template>

      <template v-slot:[`item.managementUser`]="{ item }">
        <user-avatar
          :user="item.managementUser"
          hide-roles
          disable-open-link
          show-no-user
          no-user-text="None"
        ></user-avatar>
      </template>

      <template v-slot:[`item.managementProcessed`]="{ item }">
        <v-chip
          v-if="item.managementProcessed"
          small
          x-small
          style="font-size: 11px; font-weight: 600"
          color="amber"
          text-color="gray darken-2"
        >
          <i class="far fa-check" style="line-height: 0"></i>
        </v-chip>
        <span v-else><i class="fad fa-clock fs-16px pl-2 text--disabled"></i></span>
      </template>

      <template v-slot:[`item.managementProcessedDate`]="{ item }">
        <dater
          v-if="item.managementProcessed"
          :date="item.managementProcessedDate"
          date-only
        ></dater>
        <span v-else><i class="fad fa-clock fs-16px pl-2 text--disabled"></i></span>
      </template>

      <template v-slot:[`item.targetProcessed`]="{ item }">
        <v-chip
          v-if="item.targetProcessed"
          small
          x-small
          style="font-size: 11px; font-weight: 600"
          color="amber"
          text-color="gray darken-2"
        >
          <i class="far fa-check" style="line-height: 0"></i>
        </v-chip>
        <span v-else><i class="fad fa-clock fs-16px pl-2 text--disabled"></i></span>
      </template>

      <template v-slot:[`item.targetProcessedDate`]="{ item }">
        <dater v-if="item.targetProcessed" :date="item.targetProcessedDate" date-only></dater>
        <span v-else><i class="fad fa-clock fs-16px pl-2 text--disabled"></i></span>
      </template>

      <template v-slot:[`item.createdBy`]="{ item }">
        <user-avatar :user="item.createdBy" icon></user-avatar>
      </template>

      <template v-slot:[`item.updatedBy`]="{ item }">
        <user-avatar :user="item.updatedBy" icon></user-avatar>
      </template>

      <template v-slot:[`item.createDate`]="{ item }">
        <dater :date="item.createDate" date-only></dater>
      </template>

      <template v-slot:[`item.updateDate`]="{ item }">
        <dater :date="item.updateDate" date-only></dater>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-menu dense offset-x right>
          <template v-slot:activator="{ attrs, on }">
            <v-btn icon elevation="0" v-bind="attrs" v-on="on">
              <i class="far fa-ellipsis-v" style="font-size: 16px"></i>
            </v-btn>
          </template>
          <v-list class="more-options-menu">
            <v-list-item
              @click="update(item)"
              v-if="
                $has(perms.ResourceShiftRequests.Update) &&
                item.state == enums.ResourceShiftRequestState.Draft.value
              "
            >
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small class="secondary--text">far fa-eye</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="secondary--text">View</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              @click="view(item)"
              v-else-if="
                $has(perms.ResourceShiftRequests.Update) &&
                item.state != enums.ResourceShiftRequestState.Draft.value
              "
            >
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small class="secondary--text">far fa-eye</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="secondary--text">View</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              @click="del(item)"
              v-if="
                $has(perms.ResourceShiftRequests.Delete) &&
                item.state == enums.ResourceShiftRequestState.Draft.value
              "
            >
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small>fal fa-trash-alt red--text</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="red--text">Delete</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:no-data>
        <img width="500" src="/img/art/fogg-no-connection-2.png" />
        <p class="font-weight-bold">No Data Available!</p>
      </template>
      <template v-slot:loading>
        <video width="250" muted loop autoplay style="padding: 0.5rem">
          <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
        </video>
        <p class="font-weight-bold">Searching the Cosmos...</p>
      </template>
    </v-data-table>

    <edit-shift-request
      ref="editShiftRequest"
      @save="onShiftRequestSaved"
      @sent="onShiftRequestSentToTarget"
      @close="onShiftRequestClosed"
    ></edit-shift-request>
    <view-shift-request ref="viewShiftRequest" @close="onShiftRequestClosed"></view-shift-request>
  </v-container>
</template>

<script>
import perms from "../../../plugins/permissions";
import shiftRequestsAPI from "../services/resource-shift-requests-service";
import Enums from "../../../plugins/enums";
import shiftRequestsHeader from "../config/tables/shift-requests-header";
import ResourceShiftRequestState from "../components/ResourceShiftRequestState.vue";
import enums from "../../../plugins/enums";
import EditShiftRequest from "../../ResourceShiftRequests/components/EditShiftRequest.vue";
import ViewShiftRequest from "../../ResourceShiftRequests/components/ViewShiftRequest.vue";

export default {
  data() {
    return {
      laborTypesList: this.$options.filters.EnumToList(enums.LABOR_TYPE, true),
      perms: perms,
      enums: Enums,
      storageKey: "MyShiftRequests",
      tableMode: "list",
      entities: [],
      openShiftRequestInEditMode: false,
      total: 0,
      resourceShiftRequestTimerId: null,
      valid: false,
      mainSearchInFocus: false,
      options: {
        search: null,
        sortBy: ["createDate"],
        sortDesc: [true],
        status: [],
        createdByIds: [],
        updatedByIds: [],
      },
      modals: {
        update: false,
      },
      loadingStates: {
        table: false,
      },
      defaultHeaders: [],
      headers: shiftRequestsHeader,
    };
  },
  props: {
    stage: {
      type: Object,
      default: null,
    },
  },
  computed: {
    tableHeader() {
      return this.headers.filter((elm) => !elm.hidden);
    },
  },
  created() {
    this.defaultHeaders = this.headers;
    if (this.$route.query.pq) this.options.search = this.$route.query.pq;
    if (this.storageKey) {
      this.options.itemsPerPage = +this.$getFromLocal(
        `${this.storageKey}-itemsPerPage`,
        false,
        this.options.itemsPerPage || 15
      );
    }
  },
  mounted() {
    document.querySelector("main.v-main").classList.add("sticky-main-fix");
  },
  beforeDestroy() {
    document.querySelector("main.v-main").classList.remove("sticky-main-fix");
  },
  methods: {
    onShiftRequestSentToTarget() {
      this.getData();
    },
    onShiftRequestSaved() {
      this.getData();
    },
    onShiftRequestClosed() {},
    getPhaseRecord(record) {
      return (
        record.constructionPhase.name +
        " - " +
        this.getEnumMember(this.laborTypesList, record.laborType).text
      );
    },
    createShiftRequest() {
      this.$refs.editShiftRequest.open(null);
    },
    view(shiftRequest) {
      this.$refs.viewShiftRequest.open(shiftRequest.id);
    },
    update(shiftRequest) {
      this.$refs.editShiftRequest.open(shiftRequest.id);
    },
    rowClicked(shiftRequest) {
      if (shiftRequest.state == enums.ResourceShiftRequestState.Draft.value)
        this.update(shiftRequest);
      else this.view(shiftRequest);
    },
    getDataDebounced() {
      this.$log("getDataDebounced");
      if (this.resourceShiftRequestTimerId == null) {
        this.$log("START resourceShiftRequestTimerId");
        this.resourceShiftRequestTimerId = -1;
        this.getData();
        return;
      }
      // cancel pending call
      clearTimeout(this.resourceShiftRequestTimerId);

      // delay new call 400ms
      this.resourceShiftRequestTimerId = setTimeout(() => {
        this.$log("resourceShiftRequestTimerId");
        this.getData();
      }, 400);
    },
    getData() {
      this.$log("getData()");
      this.loadingStates.table = true;
      this.$backToTop(
        0,
        document.querySelector(".resource-shift-requests-table .v-data-table__wrapper")
      );
      shiftRequestsAPI
        .getMyShiftRequests(this.$clean(this.options, true))
        .then((resp) => {
          this.entities = resp.data.items;
          this.$log("this.entities", this.entities);
          this.total = resp.data.total;
          this.loadingStates.table = false;
        })
        .catch((err) => {
          this.loadingStates.table = false;
          this.$handleError(err);
        });
    },
    del(shiftRequest) {
      this.$dialog
        .warning({
          text: `Are you sure you want to delete this Resource Shift Request?<br/>
            <code class="mono-font blue-grey lighten-5 secondary--text fs-12px">${shiftRequest.name}</code>
          `,
          title: `Delete Shift Request?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                return shiftRequestsAPI
                  .delete(shiftRequest.id)
                  .then(() => {
                    this.onResourceShiftRequestDeleted(shiftRequest.id);
                    this.$dialog.notify.success("Resource Shift Request deleted successfully!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch(() => {
                    this.$dialog.notify.error(
                      "An error occurred during deleting the resource shift request!",
                      {
                        position: "top-right",
                        timeout: 3000,
                      }
                    );
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    onResourceShiftRequestDeleted(id) {
      const index = this.entities.indexOf(this.entities.filter((e) => e.id == id)[0]);
      if (index > -1) {
        this.entities.splice(index, 1);
      }
    },
  },
  watch: {
    options: {
      handler(val) {
        this.$log("options handler");
        this.$setToLocal(`${this.storageKey}-itemsPerPage`, val.itemsPerPage);
        this.getDataDebounced();
      },
      deep: true,
    },
    shiftRequestsOrigin: {
      handler(val) {
        this.getDataDebounced();
      },
      deep: true,
    },
  },
  components: { EditShiftRequest, ViewShiftRequest, ResourceShiftRequestState },
};
</script>

<style lang="scss">
.resource-shift-requests-table {
  tbody tr:not(.v-data-table__empty-wrapper) {
    cursor: pointer;
  }
}
</style>
