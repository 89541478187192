export default [
  {
    text: "Actions",
    value: "actions",
    cellClass: "px-2",
    class: "px-2",
    align: "left",
    sortable: false,
    width: "64px",
    hidable: true,
    hidden: false,
  },
  {
    text: "Id",
    value: "id",
    cellClass: "px-1",
    width: "100px",
    class: "px-1",
    hidable: false,
    hidden: false,
  },
  {
    text: "Name",
    value: "name",
    width: "300px",
    cellClass: "px-1",
    class: "px-1",
    hidable: false,
    hidden: false,
  },
  {
    text: "Status",
    value: "state",
    cellClass: "px-1",
    class: "px-1",
    hidable: false,
    hidden: false,
  },
  {
    text: "Source User",
    value: "sourceUser",
    cellClass: "px-2",
    class: "px-2",
    hidable: false,
    hidden: false,
  },
  {
    text: "Created Date",
    value: "createDate",
    hidable: true,
    hidden: false,
  },
  {
    text: "Last Updated",
    value: "updateDate",
    hidable: true,
    hidden: false,
  },
];
